<template>
  <div class="credentials">
    <HeaderTop
      v-if="!skipOauthCredentialsScreen"
      :hide-back="backButtonIsHidden"
      @prevStep="goBack"
    />
    <div v-if="provider.name" class="credentials__inner">
      <div v-if="isProcessingOAuth && !connected" class="credentials__content">
        <div v-if="oauthPopupClosed && !form.code">
          <button class="credentials__retry" @click="retryOAuth">{{ $t("retry") }}</button>
        </div>
        <LoadingConnect
          v-else-if="!error"
          :custom-message="oauthMessage"
          :poll-replayed="pollReplayed"
        />
        <div v-else v-dompurify-html="error" class="error" />
      </div>
      <div v-else-if="isBackFromOAuth && !connected">
        <LoadingConnect
          v-if="!error"
          :custom-message="oauthMessage"
          :poll-replayed="pollReplayed"
        />
        <div v-else v-dompurify-html="error" class="error" />
      </div>
      <ValidationObserver v-else-if="!connected" ref="observer" v-slot="{ valid, handleSubmit }">
        <LoadingConnect v-if="loading" :poll-replayed="pollReplayed" />
        <Loading v-else-if="skipOauthCredentialsScreen" />
        <form v-else class="credentials-form" @submit.prevent="handleSubmit(submit)">
          <SecurityQuestion
            v-if="securityAnswerRequested && syncError"
            :provider="provider"
            :sync-error="syncError"
            :security-answer="securityAnswer"
            :loading="loading"
            @securityAnswerChange="setSecurityAnswer"
          />
          <div v-else>
            <div v-if="!isProcessingOAuth && !error" class="credentials__header">
              <div class="credentials__header-logo">
                <img :src="iconUrl(provider)" :alt="provider.name" />
              </div>
              <div class="credentials__header-description">
                <div class="credentials__header-title">{{ $t("enter_your_credentials") }}</div>
                <div v-if="provider.url" class="credentials__header-subtitle">
                  {{ provider.url }}
                </div>
              </div>
            </div>
            <div v-if="error" v-dompurify-html="error" class="error" />
            <div class="credentials__form">
              <div v-if="credentialsFormIsVisible">
                <div
                  v-dompurify-html="
                    $t('enter_credentials_to_retrieve_you_financial_data', {
                      provider: provider.name,
                    })
                  "
                  class="credentials__instructions-above"
                />
                <FormInput
                  v-for="(field, index) in credentialInputFields"
                  :key="index"
                  class="credentials__input"
                  :name="field"
                  :label="getLabel(provider, field)"
                  :value="form[field]"
                  :rules="getRules(provider, field)"
                  :type="['password', 'secret'].includes(field) ? 'password' : 'text'"
                  :disabled="loading"
                  @change="setValue"
                />
              </div>

              <div v-if="customInstructions" class="credentials__instructions-source">
                {{ team.name }} {{ $t("instructions") }}:
              </div>
              <div
                v-if="customInstructions"
                v-dompurify-html="customInstructions"
                class="credentials__instructions"
              />

              <div
                v-if="customInstructions && instructions"
                class="credentials__instructions-source"
              >
                {{ $t("original_instructions") }}:
              </div>
              <div
                v-if="instructions"
                v-dompurify-html="instructions"
                class="credentials__instructions"
              />
            </div>
          </div>

          <div class="credentials__actions">
            <!-- eslint-disable vue/no-v-html -->
            <p
              v-if="team && team.skip_first_screen"
              class="credentials__terms"
              v-html="$t('policy_and_terms_agree', { policyLink, termsLink, companyName })"
            />
            <!-- eslint-enable vue/no-v-html -->

            <button
              class="credentials__action"
              :class="{ 'mod-disabled': !valid || actionDisabled }"
            >
              <span>{{ $t(actionName) }}</span>
            </button>
          </div>

          <p v-if="team && !team.skip_first_screen" class="credentials__footer">
            {{ $t("we_use_bank_grade_encryption_to_keep_your_data_secure") }}
            <a href="https://wealthica.com/security/" target="_blank">{{ $t("learn_more") }}</a>
          </p>
        </form>
      </ValidationObserver>

      <div v-else>
        <LottieAnimation class="credentials__success-icon" name="success" />
        <div class="credentials__title">{{ $t("your_institution_is_successfully_connected") }}</div>
        <div class="credentials__redirect-message">
          {{ $t("you_are_being_redirected_back_to", { name: teamName }) }}
        </div>
        <button
          v-if="redirectURI && !['http:', 'https:'].includes(redirectURIProtocol)"
          class="credentials__redirect-button"
          @click="redirect"
        >
          {{ $t("continue") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Pusher from "pusher-js";
import { v4 as uuidv4 } from "uuid";

import LoadingConnect from "@/components/ui/LoadingConnect.vue";
import FormInput from "@/components/ui/FormInput.vue";
import SecurityQuestion from "@/components/ui/SecurityQuestion.vue";
import addingInstitution from "@/mixins/addingInstitution";
import providerInfo from "@/mixins/providerInfo";
import { getRedirectOAuthURL, getPopupOAuthURL } from "@/utils/oauth";
import openPopup from "@/utils/openPopup";

import LottieAnimation from "@/components/ui/LottieAnimation.vue";
import redirection from "@/mixins/redirection";
import { PUSHER_API_KEY, WEALTHICA_APP_URL } from "@/constants";
import HeaderTop from "@/components/ui/HeaderTop.vue";

import Loading from "@/components/ui/Loading.vue";
import inIframe from "../utils/inIframe";

export default {
  components: {
    Loading,
    HeaderTop,
    LottieAnimation,
    FormInput,
    SecurityQuestion,
    LoadingConnect,
  },
  mixins: [providerInfo, addingInstitution, redirection],
  data() {
    return {
      form: {},
      error: "",
      connected: false,
      oauthMessage: "",
      isProcessingOAuth: false,
      oauthClosedInterval: null,
      oauthClosedTimeout: null,
      oauthPopupClosed: false,
    };
  },
  computed: {
    ...mapGetters("app", [
      "loading",
      "providerIsPreselected",
      "token",
      "clientId",
      "redirectURI",
      "isBackFromOAuth",
      "securityAnswerRequested",
      "lang",
    ]),
    ...mapGetters("team", [
      "team",
      "teamFeatures",
      "teamName",
      "policyLink",
      "termsLink",
      "companyName",
    ]),
    ...mapGetters("app", ["lang"]),
    title() {
      if (this.securityAnswerRequested && this.syncError) return this.$t("security_question");
      if (this.isOauth)
        return this.isReconnect ? this.$t("reconnect_institution") : this.$t("connect_institution");

      return this.$t("enter_your_credentials");
    },
    actionName() {
      if (this.provider.auth_type === "password") return "sign_in";

      return "connect_institution";
    },
    actionDisabled() {
      if (this.loading) return true;

      return false;
    },
    credentialsFormIsVisible() {
      return !this.isOauth;
    },
    credentialInputFields() {
      return this.provider.credentials || ["username", "password"];
    },
    isReconnect() {
      return this.$route.name === "reconnect-institution";
    },
    redirectURIProtocol() {
      try {
        return new URL(this.redirectURI).protocol;
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
        return "";
      }
    },
    customInstructions() {
      const instructions = this.team?.instructions?.find(
        instruction => instruction.provider === this.provider.type
      );
      if (!instructions) return "";

      return instructions.instructions[this.lang] || instructions.instructions.en;
    },
    arrowAnimationName() {
      return this.theme === "dark" ? "arrows-connect-dark" : "arrows-connect-light";
    },
    backButtonIsHidden() {
      if (this.providerIsPreselected && this.team && this.team.skip_first_screen) return true;

      return false;
    },
    skipOauthCredentialsScreen() {
      if (
        (this.team?.auto_start_oauth || this.$route.query.auto_start_oauth) &&
        this.isOauth &&
        !this.isBackFromOAuth &&
        // Not possible to skip oauth credential screen in iframe mode because open.popup is blocked by browser
        !inIframe()
      ) {
        return true;
      }

      return false;
    },
  },
  async created() {
    // set form keys
    if (this.provider.credentials && this.provider.credentials.length) {
      this.form = this.provider.credentials.reduce((form, item) => ({ ...form, [item]: "" }), {});
    }

    // Process oAuth Authorization Response
    if (this.isBackFromOAuth && this.$route.query.code) {
      this.oauthMessage = "access_granted_logging_in";

      this.form.code = this.$route.query.code;
      this.form.client_id = this.provider.clientId;
      this.submitInstitution();
      return;
    }

    if (this.skipOauthCredentialsScreen) {
      this.submit();
    }
  },
  beforeDestroy() {
    clearTimeout(this.oauthClosedTimeout);
    clearInterval(this.oauthClosedInterval);
  },
  methods: {
    ...mapActions("institutions", ["resetInstitution"]),
    ...mapActions("app", ["setLoading", "goToConnectInstitutionStep", "fireEvent"]),
    iconUrl(provider) {
      return `${WEALTHICA_APP_URL}/images/institutions/${provider.type}.png`;
    },
    getLabel(provider, field) {
      // if specific "key" or "secret" label is set (en is a fallback option if it's set)
      const label = provider?.[field]?.label?.[this.lang] || provider?.[field]?.label?.en;

      return label || this.$t(field);
    },
    getRules(provider, field) {
      const isOptional = provider?.[field]?.optional;
      const rules = !isOptional ? { required: true } : {};

      // Use custom pattern from config if it's set
      const pattern = provider?.[field]?.pattern;
      if (pattern) rules.regex = new RegExp(pattern);

      return rules;
    },
    goBack() {
      this.fireEvent({ name: "connect flow - go back" });

      if (this.providerIsPreselected) {
        this.goToConnectInstitutionStep();
      } else {
        this.$router.go(-1);
      }

      this.setSecurityAnswerRequested(false);
      this.resetInstitution();
      this.setLoading(false);
    },
    async submit() {
      if (this.actionDisabled) {
        console.error("Submit action disabled");
        return;
      }

      this.error = "";

      // For Wealthica OAuth Connect (direct URL): Start connect OAuth provider flow via redirect
      if (this.isOauth && !inIframe()) this.startOAuthRedirectFlow();
      // For Wealthica SDK Connect (iFrame): Start connect OAuth provider flow in popup
      else if (this.isOauth && inIframe()) this.startOAuthPopupFlow();
      else if (this.securityAnswer && this.syncError) await this.submitAnswer();
      else await this.submitInstitution();
    },
    setValue({ key, value }) {
      this.form[key] = value;
    },
    setSecurityAnswer(value) {
      this.securityAnswer = value;
    },
    startOAuthRedirectFlow() {
      window.location.href = getRedirectOAuthURL(
        this.provider,
        this.isReconnect,
        this.$route.params.institution
      );
    },
    startOAuthPopupFlow() {
      const pusher = PUSHER_API_KEY ? new Pusher(PUSHER_API_KEY) : null;
      const channelName = uuidv4();
      const pusherChannel = pusher.subscribe(channelName);

      this.isProcessingOAuth = true;
      this.oauthMessage = "waiting_for_authorization";

      const oauthPopup = openPopup(getPopupOAuthURL(this.provider, channelName), "", 600, 725);

      this.oauthClosedInterval = setInterval(() => {
        if (!oauthPopup?.closed) return;

        clearInterval(this.oauthClosedInterval);
        clearTimeout(this.oauthClosedTimeout);

        // wait some time (3 seconds) for pusher callback
        this.oauthClosedTimeout = setTimeout(() => {
          if (this.form.code || this.error) return;

          this.oauthPopupClosed = true;

          this.redirectWithError(400, `${this.$t("authorization_failed")}`);
        }, 3000);
      }, 1000);

      pusherChannel.bind("oauth:done", data => {
        pusherChannel.unbind();

        // Process oAuth Authorization Response
        if (data.code) {
          this.oauthMessage = "access_granted_logging_in";
          this.form.code = data.code;
          this.submitInstitution();
          return;
        }

        // Process oAuth Error Response
        if (data.error) {
          this.redirectWithError(
            400,
            `${this.$t("authorization_failed")} ${this.$t("oauth_error", { prop: data.error })}`
          );
        }
      });
    },
    retryOAuth() {
      this.isProcessingOAuth = false;
      this.oauthPopupClosed = false;
    },
    redirect() {
      this.redirectWithInstitution(this.institution);
    },
  },
};
</script>

<style lang="scss">
.credentials {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    margin-top: 3rem;
    @include title-1;
  }

  &__icon-connect {
    display: flex;
    width: 50px;
    margin: 0 5px;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: $contentMaxWidth;
    margin: 0 auto;
    text-align: left;
  }

  &__header-logo {
    display: flex;
    width: 60px;
    min-width: 60px;
    height: auto;
    border-radius: 5px;
    margin-right: 10px;
    overflow: hidden;

    img {
      width: inherit;
    }
  }

  &__header-title {
    @include title-1;
  }

  &__header-description {
    width: calc(100% - 82px);
  }

  &__header-subtitle {
    padding-left: 1px;
    font-size: 13px;
    color: $gray-500;
    line-height: 1.1;
    word-break: break-word;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    > span {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &__form {
    margin: 1rem auto;
    text-align: left;

    > div {
      margin-bottom: 1rem;
    }
  }

  &__actions {
    position: relative;
    margin-top: auto;
    margin-bottom: 10px;
  }

  &__action {
    @include btn-default;

    &.mod-disabled {
      @include btn-disabled;
    }
  }

  &__action-loading {
    width: 2rem;
    height: 2rem;
    margin-top: -0.25rem;
    color: white;
  }

  &__instructions-source {
    color: $gray-400;
    font-size: 0.75rem;
    margin-bottom: 0;
    line-height: 1.25;
  }

  &__instructions {
    color: $gray-800;
    font-size: 14px;
    line-height: 20px;

    margin-bottom: 20px;

    a {
      color: var(--primary-color);

      transition: 0.3s ease;

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &__actions {
    position: relative;
    margin-top: auto;
    margin-bottom: 10px;
  }

  &__action {
    @include btn-default;

    &.mod-disabled {
      @include btn-disabled;
    }
  }

  &__action-loading {
    width: 2rem;
    height: 2rem;
    margin-top: -0.25rem;
    color: white;
  }

  &__instructions-above {
    color: $gray-800;
    font-size: 15px;
    line-height: 1.25;

    margin-bottom: 2rem;
  }

  &__instructions-source {
    color: $gray-400;
    font-size: 0.75rem;
    margin-bottom: 0;
    line-height: 1.25;
  }

  &__instructions {
    color: $gray-800;
    font-size: 0.875rem;
    line-height: 1.25;

    margin-bottom: 0.5rem;

    a {
      color: $blue-400;

      transition: 0.3s ease;

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &__footer,
  &__terms {
    margin: 0 auto;
    max-width: 280px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;

    &--ledger {
      padding-bottom: 30px;
    }

    a {
      color: #000;
      font-weight: 400;
      text-decoration: underline;
      transition: 0.3s ease;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__terms {
    margin-bottom: 18px;
  }

  &__redirect-message {
    margin-top: 0.75rem;
    margin-bottom: 4rem;
    text-align: center;
    color: $gray-400;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  &__process-loading {
    margin-top: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
    color: $gray-200;
  }

  &__success-icon {
    margin: 0 auto 1rem;
    width: 7rem;
    height: 7rem;
  }

  &__retry {
    @include btn-default;

    display: inline-flex;
    align-items: center;

    margin: 1.5rem auto 0;
  }

  &__redirect-button {
    @include btn-default;

    display: inline-flex;
    align-items: center;
    margin-top: 0;
  }

  &__logos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__logo-client,
  &__logo-app {
    margin: 0 0.5rem;
    z-index: 2;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    color: var(--primary-color);
  }

  &__join {
    color: $gray-300;
    font-size: 20px;
  }

  &__logo-client {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem;
    color: #fff;
    font-weight: 700;
    font-size: 12px;

    background-color: var(--primary-color);

    img {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
    }
  }
}
</style>

<style lang="scss">
.theme-dark {
  .credentials__action,
  .credentials__retry,
  .credentials__redirect-button {
    &.mod-disabled {
      @include btn-disabled-dark;
    }
  }

  .credentials__footer a {
    color: #fff;
  }

  .credentials__logo {
    background-color: #fff;

    // to fix white line on border radius
    will-change: transform;
  }
}
</style>

<style lang="scss" scoped>
.credentials-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: $contentMaxWidth;
  margin: 0 auto;
  padding-bottom: 30px;

  &.hidden {
    display: none;
  }
}
</style>
